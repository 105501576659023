// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-zh-index-js": () => import("./../../../src/pages/zh/index.js" /* webpackChunkName: "component---src-pages-zh-index-js" */),
  "component---src-templates-page-job-zh-js": () => import("./../../../src/templates/PageJob_zh.js" /* webpackChunkName: "component---src-templates-page-job-zh-js" */),
  "component---src-templates-page-jobs-en-js": () => import("./../../../src/templates/PageJobs_en.js" /* webpackChunkName: "component---src-templates-page-jobs-en-js" */),
  "component---src-templates-page-jobs-zh-js": () => import("./../../../src/templates/PageJobs_zh.js" /* webpackChunkName: "component---src-templates-page-jobs-zh-js" */)
}

